import React from "react";
import { externalURL } from "../../config/url";
import Apple from "../../images/appstore-download.svg";
import Playstore from "../../images/playstore-download.svg";
import * as styles from "./ProductDownload.module.scss";
import { Col, Row } from "../layout/Grid";
import ScrollReveal from "../../components/layout/ScrollReveal";

const ProductDownload = () => {
  return (
    <>
      <section className="section wrapper">
        <Row>
          <Col width="40%">
            <ScrollReveal effect="slide-right" delay={50}>
              <h3 className="h2">Make a booking at your fingertips</h3>
            </ScrollReveal>
          </Col>
          <Col width="50%">
            <ScrollReveal effect="slide-left" delay={50}>
              <p>
                A mobile app for customers to make bookings, confirm attendance,
                and receive real-time notifications. Customers can make an
                instant next-available-slot booking by scanning the QR code in
                front of the store. They will receive real-time notifications of
                estimated waiting times via the app.
              </p>
              <div className={styles["download"]}>
                <a
                  href={externalURL.addpointment}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Apple} alt="Download on App Store"></img>
                </a>
                <a
                  href={externalURL.addpointment}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Playstore} alt="Download on Play Store"></img>
                </a>
              </div>
            </ScrollReveal>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ProductDownload;
