import React, { useRef, useEffect, useState } from "react";
import * as styles from "./ProductWebsite.module.scss";
import { Col, Row } from "../layout/Grid";
import img from "../../images/addpointment-home.jpg";
import classNames from "classnames";

const ProductWebsite = () => {
  const [enter, setEnter] = useState(false);
  const inputRef = useRef();

  const scrollHandler = () => {
    if (inputRef.current.getBoundingClientRect().top <= 10) {
      setEnter(true);
    }
    if (inputRef.current.getBoundingClientRect().bottom <= 10) {
      setEnter(false);
    }
  };

  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("scroll", scrollHandler, true);
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);
  return (
    <section ref={inputRef}>
      <div className="section wrapper">
        <Row className={classNames(enter ? styles.entered : null)}>
          <Col width="30%">
            <div className="h4">
              Fit for all types of businesses and customers in organising hectic
              schedules through multiple booking options
            </div>
          </Col>
          <Col width="65%">
            <div className={styles["screenshot"]}>
              <img src={img} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ProductWebsite;
